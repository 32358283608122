import footballAPIImgSrc from "./_images/logo_trans_bg_thin_border.png";
import omdbApiImgSrc from "./_images/omdb_api.png";
import tunerImgSrc from "./_images/tuner.png";
import imageOptimizerImgSrc from "./_images/image-optimizaton-ui.png";
import burgerImgSrc from "./_images/burger.svg";
import newsFeedImgSrc from "./_images/news-feed.png";
import bubblesImgSrc from "./_images/bubbles.png";
import authFlowImgSrc from "./_images/auth-flow-login.png";

export const projects = [
  {
    id: 8,
    title: "Login Flow With Google OAuth Integration",
    tags: [
      "node",
      "express",
      "sqlite",
      "sequelize",
      "google oauth",
      "google api",
      "css",
      "js",
      "tailwind",
    ],
    imgSrc: authFlowImgSrc,
    githubUrl:
      "https://github.com/RL4444/login-flow-with-google-oauth-integration",
    paddedCard: false,
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
  {
    id: 6,
    title: "Digital Guitar Tuner",
    tags: ["electron", "web audio API", "html", "css", "js"],
    imgSrc: tunerImgSrc,
    githubUrl: "https://github.com/RL4444/digital-guitar-tuner",
    paddedCard: false,
    description: "this is a short descipriton",
    liveDemoUrl: "/tuner",
  },
  {
    id: 1,
    title: "Live Football Scores Scraper PreProcessor with Data Persistence",
    tags: ["node", "express", "nodemailer", "mongodb", "node-cron"],
    imgSrc: footballAPIImgSrc,
    githubUrl: "https://github.com/RL4444/football-scores-app/",
    paddedCard: true,
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
  {
    id: 5,
    title: "News Feed With User Tailored Feed",
    tags: ["vue", "typescript", "cheerio", "UI/UX", "tailwind"],
    imgSrc: newsFeedImgSrc,
    githubUrl:
      "https://github.com/RL4444/news-feed-with-user-interaction-statistic-generation",
    paddedCard: false,
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
  {
    id: 3,
    title: "Web Image Optimizer",
    tags: ["file encoding", "electron", "html", "js", "css"],
    imgSrc: imageOptimizerImgSrc,
    githubUrl: "https://github.com/RL4444/image-optimizer-electron",
    paddedCard: true,
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
  {
    id: 4,
    title: "Burger Restraunt Locator based on Live Location",
    tags: ["react", "express", "node", "API Integration"],
    imgSrc: burgerImgSrc,
    githubUrl: "https://github.com/RL4444/burger_buddy/",
    paddedCard: true,
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
  {
    id: 7,
    title: "Apple Music Inspired Bubble Picker",
    tags: [
      "react",
      "typescript",
      "visJs",
      "react testing library",
      "API Integration",
    ],
    imgSrc: bubblesImgSrc,
    githubUrl: "https://github.com/RL4444/apple-bubble-ui-movie-recommender",
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
  {
    id: 2,
    title: "Movie Search App",
    tags: [
      "react",
      "typescript",
      "cypress",
      "react testing library",
      "API Integration",
    ],
    imgSrc: omdbApiImgSrc,
    githubUrl: "https://github.com/RL4444/movie_search",
    paddedCard: true,
    description: "this is a short descipriton",
    liveDemoUrl: null,
  },
];
